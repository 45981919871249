/*eslint-disable camelcase*/
import {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import adsConfig from '../../constants/ads-config';
import { bfUrl, CLUSTER, LOCAL_API, ROUTED_API } from '../../constants';
import { FEED_CONTENT_TRANSITION_END } from '../../constants/feeds';
import { AdInFeed } from '../Ads/units/AdInFeed';
import { sizes } from '@buzzfeed/adlib/dist/module/bindings/react';
import sizeHelper from '@buzzfeed/adlib/services/size/standalone';
import { useMediaQuery, useTrackingContext } from '../../hooks';
import { StoryFeedManager } from '../Ads/managers/StoryFeedManager';
import PropTypes from 'prop-types';
import LinkWithTracking from '../LinkWithTracking';
import ContentComponent from './ContentComponent';
import { AdSection } from '../Ads/units/AdSection';
import AdStickySidebar from './AdStickySidebar';
import FeedBottom from '../FeedBottom';
import FeedsButton from '../FeedsButton';
import styles from './FeedContent.module.scss';
import { ChevronRight } from '../../icons/ChevronRight';

const nextApiRequest = async (nextUrl) => {
  /**
   * Use local API route path (`/api/next`) if on dev or served from buzzfeed.io (namespace and
   * stage), otherwise use the routed API path (`/public-feed-data`)  to circumvent CORS issues on
   * non prod environments.
   */
  const endpoint = CLUSTER === 'dev' || window.location.hostname.includes('buzzfeed.io')
    ? LOCAL_API
    : ROUTED_API;

  const { pathname, search } = new URL(nextUrl);
  const apiPath = pathname.split('feed-api/v1/')[1];
  const relativePath = `${endpoint}next/${apiPath}${search}`;

  try {
    return await fetch(relativePath, { 'Content-Type': 'application/json' });
  } catch (err) {
    console.error(err);
    return {};
  }
};

/**
 * FeedContent component renders the content for the feed with ads placed at specified intervals and positions.
 *
 * @param {number} [adsAfterEveryNthPosition=5] - The interval at which ads should be inserted after every Nth item.
 * @param {number[]} [adsAfterInitialPositions=[]] - Specific positions after which ads should be inserted.
 * @param {number} [adsStartIndex=1] - The index at which to start the ad manager configuration on. For example, if
 * the value were `2`, the first ad at index 1 would be `story2`.
 * @param {React.ReactNode} children - The content items to display before the feed.
 * @param {Object} [data={}] - Additional data for the feed content.
 * @param {Function} getTrackingDataWithPosition - This function takes the current post index and
 * lets the parent component determine the tracking properties to return, which can include a
 * calculated position value based on the index provided to the function. Some components may
 * increment this value and return it in the `position_in_unit` or `position_in_subunit` properties.
 * @param {string} [headline=''] - The headline text for the feed.
 * @param {boolean} [isTrackable=false] - Flag to enable or disable tracking.
 * @param {number} [maxItemsPerGrouping=5] - Maximum number of feed items per grouping of content
 * @param {string} [pageName=''] - The name of the page where the feed is displayed.
 * @param {boolean} [showEndOfFeedCard=false] - Flag to show or hide the end of feed card.
 * @param {boolean} [showNumbering=false] - Flag to show or hide numbering for the feed items.
 * @param {Object} [sponsor={}] - Sponsor information for the feed content.
 * @param {Object} [trackingData={}] - Tracking data for analytics.
 * @param {Object} props - Additional catchall props to pass to the component.
 */
export const FeedContent = ({
  adsAfterEveryNthPosition = 5,
  adsAfterInitialPositions = [],
  adsStartIndex = 1,
  children,
  data = {},
  getTrackingDataWithPosition,
  headline = '',
  isTrackable = false,
  maxItemsPerGrouping = 5,
  pageName = '',
  showEndOfFeedCard = false,
  showNumbering = false,
  sponsor = {},
  trackingData = {},
  ...props
}) => {
  const adsContext = useContext(AdsContext);
  const [isContentReady, setContentReady] = useState(false);
  const [adManager, setAdManager] = useState(null);
  const { isMobile } = useMediaQuery();
  const [feedItems, setFeedItems] = useState(data.items || []);
  const [nextUrl, setNextUrl] = useState(data.next);
  const [isFetchingLoadMore, setIsFetchingLoadMore] = useState(false);
  const { trackContentAction } = useTrackingContext();
  const showSection = data.name === 'tab_trending' || data.name === 'tab_latest';
  const hasSponsor = !!sponsor?.name?.length;

  const ctaLink = useMemo(() => {
    const commonCtaTrackingData = {
      ...trackingData,
      item_type: 'text',
      target_content_id: 6,
      target_content_type: 'feed'
    };

    switch (data.name) {
      case 'tab_quiz':
        return {
          url: `${bfUrl}/quizzes`,
          text: 'See More Quizzes',
          trackingData: {
            ...commonCtaTrackingData,
            item_name: 'see_more_quizzes',
          }
        };
      case 'tab_trending':
        return {
          url: `${bfUrl}/trending`,
          text: 'See More Trending',
          trackingData: {
            ...commonCtaTrackingData,
            item_name: 'see_more_trending',
          }
        };
      default:
        return {
          url: '',
          text: '',
          trackingData: {
            ...commonCtaTrackingData,
          }
        };
    }
  }, [data.name, trackingData]);

  useEffect(() => {
    if (!feedItems || adsContext.status !== 'loaded') {
      return () => {};
    }

    /**
     * If the transition effect is not applied, set the content ready state to true. Otherwise, wait
     * for the transition effect to end before setting the content ready state to true.
     */
    if (!props.isNext) {
      setContentReady(true);
    }

    const transitionEndHandler = () => {
      setContentReady(true);
    };

    // Wait for transition effects to end before setting the ad manager
    window.addEventListener(FEED_CONTENT_TRANSITION_END, transitionEndHandler);

    return () => {
      window.removeEventListener(FEED_CONTENT_TRANSITION_END, transitionEndHandler);
      setContentReady(false);
    };
  }, [adsContext, props.isNext]);

  /**
   * Initialize the ad manager when the content is ready.
   */
  useEffect(() => {
    if (!isContentReady || !feedItems.length) {
      return () => {};
    }

    const unitConfig = [];

    for (let index = adsStartIndex; adsConfig[`story${index}`] !== undefined; index++) {
      // Do something with adsConfig[`story${index}`]
      const config = adsConfig[`story${index}`];
      // remove 970xN sizes because left side only
      config.size = sizeHelper.exclude(
        config.size,
        sizes.PROGRAMMATIC_BILLBOARD,
        sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      );
      unitConfig.push(config);
    }

    const manager = new StoryFeedManager({
      config: {
        units: unitConfig,
        // Density is set to zero so that ads are placed in consecutive indexes (0, 1, 2, etc..).
        // Placements are determined by the logic in this component instead of the ad manager, where
        // the first ad is requested at index 0 and then incremented by 1 for each subsequent ad
        // placement.
        density: 0,
      },
    });

    const initManager = async () => {
      try {
        await manager.init();
        setAdManager(manager);
      } catch (error) {
        console.error(error);
      }
    };

    initManager();

    return () => {
      manager.destroy();
      setAdManager(null);
    };
  }, [isContentReady, adsStartIndex]);

  const handleLoadMore = async () => {
    setIsFetchingLoadMore(true);
    if (isTrackable) {
      // remove target_content_id, target_content_type, target_content_url from trackingData
      trackContentAction({
        ...trackingData,
        action_type: 'show',
        action_value: 'load_more_posts',
        item_name: 'load_more_posts',
        item_type: 'button',
        target_content_id: '',
        target_content_type: '',
        target_content_url: ''
      });
    }
    const response = await nextApiRequest(nextUrl);

    if (response.ok) {
      const { items, next } = await response?.json();

      setFeedItems((prevFeedItems) => [
        ...prevFeedItems,
        ...items,
      ]);
      setNextUrl(next);
    } else {
      // If an error occurs, this will hide the "load more" button
      setNextUrl(null);
    }

    setIsFetchingLoadMore(false);
  };

  const feedListEnd = (
    <>
      {!!ctaLink.url.length && (
        <li className={styles.ctaWrap}>
          <LinkWithTracking href={ctaLink.url} commonTrackingData={ctaLink.trackingData}>
            {ctaLink.text}
            <ChevronRight/>
          </LinkWithTracking>
        </li>
      )}

      {nextUrl && !ctaLink.url.length &&
        <li
          className={
            `${styles.loadMoreButton} ${isFetchingLoadMore ? styles.fetchingMoreFeedItems : ''}`
          }
        >
          <FeedsButton
            onClick={handleLoadMore}
            title="Load More Posts"
          />
        </li>
      }

      {showEndOfFeedCard && !nextUrl &&
        <li>
          <FeedBottom
            hasSponsor={hasSponsor}
            isTrackable={isTrackable}
            trackingData={trackingData}
          />
        </li>
      }
    </>
  );

  const feedList = useMemo(() => {
    // Sort the nth ad positions in ascending order
    const initialPositions = [...adsAfterInitialPositions].sort((a, b) => a - b);

    const lastPosition = initialPositions[initialPositions.length - 1] || 0;
    const groupedContent = [];

    let content = [];
    let adIndex = 0;

    // Offset to use on subsequent groups to properly calculate the modulo of `maxItemsPerGrouping`.
    let indexOffset = 0; //maxItemsPerGrouping ? 0 : lastPosition;

    const startIndex = data?.startIndexLabel || 0;

    const getTrackingData = (index, { object_type, id }) => {

      let trackingDataWithPosition = { position_in_unit: index - 1 };

      if (object_type === 'package') {
        return {
          subunit_name: `package_${id}`,
          subunit_type: 'package',
          ...trackingDataWithPosition,
        };
      } else {
        if (typeof getTrackingDataWithPosition === 'function') {
          const customPositionTrackingData = getTrackingDataWithPosition(index - 1) || {};
          if (typeof customPositionTrackingData === 'object' && !!Object.keys(customPositionTrackingData).length) {
            trackingDataWithPosition = customPositionTrackingData;
          }
        }
        return { ...trackingData, ...trackingDataWithPosition };
      }
    };

    for (let index = 1; index <= feedItems.length; index++) {
      const item = feedItems[index - 1];
      const trackingDataForItem = getTrackingData(index, item);
      // The zero index will always be the current nth position if there are any remaining. This is
      // because once the index is used, it is removed from the list (initialPositions.shift()).
      const currentPosition = initialPositions[0] ?? 0;
      // Determine if the current iteration is the last iteration of the feed items.
      const isLastItem = index === feedItems.length;
      // The index of the current group.
      const groupIndex = groupedContent.length;
      // Determine if the current group is the first group.
      const isFirstGroup = groupIndex === 0;
      // The size of the first group will be based on the last ad position plus a buffer of
      // `adsAfterEveryNthPosition`.
      const isFirstGroupWithAds = isFirstGroup && !!lastPosition;
      // When maxItemsPerGrouping is 0, ignore `maxItemsFirstGroupWithAds` to place all items in
      // one group.
      const maxItemsFirstGroupWithAds = maxItemsPerGrouping ? lastPosition + adsAfterEveryNthPosition : 0;
      // Place an ad after the current position in the list of initial ad positions. If there are no
      // items in the list, default to the defined value of `adsAfterEveryNthPosition`.
      const adsAfterNthPosition = isFirstGroupWithAds && currentPosition
        ? currentPosition
        : adsAfterEveryNthPosition;

      /**
       * Items will continue to be added to the initial group while there are still positions to be
       * used. Otherwise, use `maxItemsPerGrouping` value. To determine if the the last iteration
       * of subsequent groups, subtract the last position from the current index and use the modulo
       * of `maxItemsPerGrouping`.
       * - When maxItemsPerGrouping is 0, ignore `maxItemsFirstGroupWithAds` to place all items in
       *   one group.
       */
      const isLastItemOfGroup = isFirstGroupWithAds
        ? index % maxItemsFirstGroupWithAds === 0
        : Math.max(index - indexOffset, 1) % maxItemsPerGrouping === 0;

      /**
       * Determine if an inline ad should be rendered. An ad should be rendered if...
       *  - It is not the last iteration of a group
       *  - It is not the last iteration of all feed items.
       *  - The current index minus index offset is divisible by the adsAfterNthPosition value.
       */
      const shouldRenderInlineAd = (
        !isLastItem &&
        !isLastItemOfGroup &&
        Math.max(index - (isFirstGroupWithAds && !currentPosition ? lastPosition : indexOffset), 1) % adsAfterNthPosition === 0
      );

      content.push((
        <li key={`content-${item.id}`}>
          <ContentComponent
            objectType={item.object_type}
            className={
              item.object_type === 'bfp_content'
                ? styles.bfpContent
                : pageName === 'topic' ? 'topicPostCard' : ''
            }
            content={item.content}
            contentObjectId={item.id}
            index={showNumbering ? String(index + startIndex) : null}
            isTrackable={isTrackable}
            trackingData={trackingDataForItem}
            showSection={showSection}
          />
        </li>
      ));

      if (shouldRenderInlineAd) {
        const adItem = adManager?.getAd(adIndex);
        // If ad manager determines that ads slots are "done", don't render the ad placeholder.
        if (!(adManager && adItem && adManager.isDone(adItem))) {
          content.push((
            <li
            className={styles.feedItemAd}
            key={`ad-${adIndex}`}
            >
              <AdInFeed
                config={adItem?.slot}
                renderPlaceholderOnly={!adManager}
              />
            </li>
          ));
        }

        adIndex++;
        initialPositions.shift();
      }

      if (isLastItem) {
        content.push(feedListEnd);
      }

      if (isLastItemOfGroup || isLastItem) {
        groupedContent.push(
          <>
            <div key={`feedGroupWrapper-${groupIndex}`} className={styles.contentContainer}>
              {isFirstGroup &&
                <>
                  {children && (
                    <section className={styles.top}>
                      {/**
                       * Top section components can be passed in as children (Content Modules, Social Units,
                       * mini feeds, etc).
                       *
                       * @todo
                       * FeedContent is intended to handle rendering any type of content, but only post types
                       * are handled at the moment. When this is updated, passing in children may not be
                       * necessary depending on the approach.
                       */}
                      {children}
                    </section>
                  )}

                  {headline &&
                    <header className={styles.header}>
                      <h2 className={styles.headline}>{headline}</h2>
                    </header>
                  }
                </>
              }

              <ul
                className={`${styles.content} ${pageName === 'topic' ? ` ${styles.topic}` : '' }`}
                >
                {content}
              </ul>
              <AdStickySidebar position={isFirstGroup ? 'bigstory' : groupIndex} />
            </div>
            {!isLastItem && (
              <AdSection
                isMobile={isMobile}
                key={`ad-section-${adIndex}`}
                posnum={adIndex + adsStartIndex}
                stickyWithinPlaceholder={true}
              />
            )}
          </>
        );

        if (!isLastItem) {
          // Increment the adIndex to account for the ad section components rendered in between grouped items
          adIndex++;
        }

        indexOffset = index;
        content = [];
      }
    }

    if (feedItems.length === 0 && children) {
      groupedContent.push(<>
        <div key={`feedGroupWrapper-${0}`} className={styles.contentContainer}>
          <>
            {children && (
              <section className={styles.top}>
                {children}
              </section>
            )}
          </>
          <AdStickySidebar position='bigstory' />
        </div>
      </>);
    }

    return groupedContent;
  }, [
    adManager,
    adsAfterEveryNthPosition,
    children,
    feedItems,
    feedListEnd,
    headline,
    isMobile,
    isTrackable,
    maxItemsPerGrouping,
    pageName,
    showNumbering,
    showSection,
    trackingData,
  ]);

  return feedList;
};

FeedContent.propTypes = {
  adsAfterEveryNthPosition: PropTypes.number,
  adsAfterInitialPositions: PropTypes.arrayOf(PropTypes.number),
  adsStartIndex: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  data: PropTypes.object.isRequired,
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node, // JSX node
    PropTypes.elementType // React component type
  ]),
  isTrackable: PropTypes.bool,
  maxItemsPerGrouping: PropTypes.number,
  pageName: PropTypes.string,
  showEndOfFeedCard: PropTypes.bool,
  showNumbering: PropTypes.bool,
  sponsor: PropTypes.object,
  trackingData: PropTypes.object,
};

export default FeedContent;
